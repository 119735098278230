import {BrowserRouter, Route, Routes} from 'react-router-dom';
import WallSeeTroughRoute from './wall-see-trough';
import SiloDemoRoute from "./silo-demo";
import WallViewerRoute from './wall-viewer/index.js';

const Router = ({setShowVersionInfoDialog}) => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/wall-see-trough" exact element={<WallSeeTroughRoute />}/>
                <Route path="/silo-demo" exact element={<SiloDemoRoute />}/>
                <Route path="/wall-viewer" exact element={<WallViewerRoute />}/>
            </Routes>
        </BrowserRouter>
    )
}

export default Router;
