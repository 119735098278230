import './App.css';
import Router from './routes/router';

function App() {
    return (
        <>
            <Router />
        </>
    );
}

export default App;
