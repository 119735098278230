import { useEffect, useRef } from 'react';
import {Box, makeStyles} from "@material-ui/core";

const THREE = window.THREE;
const THREEx = window.THREEx;

const useStyles = makeStyles((theme) => {
    return {
        rootBox: {
            // width: '100vw',
            // height: '100vh',
        },
        canvasWrapper: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'hidden'
        },
        canvas: {
            position: 'absolute',
            top: '0px',
            left: '0px'
        }
    };
});

const WallViewerRoute = () => {
    const canvas = useRef(null);
    const canvasWrapper = useRef(null);
    const scene = useRef(null);
    // const [textFont, setTextFont] = useState(null);
    // const mode = 'development';
    const arToolkitSource = useRef(null);
    const arToolkitContext = useRef(null);
    const renderer = useRef(null);
    const onRenderFcts = useRef([]);
    const orbitControls = useRef(null);
    const camera = useRef(undefined);
    const arMarkerControls = useRef(undefined);
    const classes = useStyles();
    const wallInScene = useRef(undefined);

    useEffect(() => {
        renderer.current = new THREE.WebGLRenderer({
            canvas: canvas.current,
            antialias: true,
            alpha: true
        });

        scene.current = new THREE.Scene();
        window.scene = scene.current;


        // scene.current.add(new THREE.AxesHelper(3));
        // scene.current.add(new THREE.GridHelper(20, 20, 0xeeeeee, 0xeeeeee));


        // ambient light
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        ambientLight.name = 'Abmient light';
        scene.current.add(ambientLight);

        // sun (main light)
        const sunLight = new THREE.DirectionalLight(0xffffff, 0.2);
        sunLight.name = "Sunlight";
        sunLight.position.set(2, 3, 2);
        scene.current.add(sunLight);

        // top light
        // const topLight = new THREE.DirectionalLight(0xffffff, 0.1);
        // topLight.name = "Toplight";
        // topLight.position.set(-0.1, 5, 0);
        // scene.current.add(topLight);

        // extra light
        const extraLight = new THREE.DirectionalLight(0xffffff, 0.2);
        extraLight.name = "Extra light";
        extraLight.position.set(-2, 3, 2);
        scene.current.add(extraLight);



        //
        // // back light
        const backLight = new THREE.DirectionalLight(0xffffff, 0.2);
        backLight.name = "Backlight";
        backLight.position.set(-1, 3, -2);
        scene.current.add(backLight);

// // back light
        const backLight2 = new THREE.DirectionalLight(0xffffff, 0.2);
        backLight2.name = "Backlight 2";
        backLight2.position.set(1, 3, -20);
        scene.current.add(backLight2);





        const loader = new THREE.GLTFLoader();
        loader.load('/wall-viewer.glb', function (gltf) {
            gltf.scene.traverse(child => {
                if (child.name === 'WALL' && child.type === 'Mesh') {
                    wallInScene.current = child;
                }
            });
            gltf.scene.scale.x = 0.5;
            gltf.scene.scale.y = 0.5;
            gltf.scene.scale.z = 0.5;
            scene.current.add(gltf.scene);
        }, undefined, function (error) {
            console.error(error);
        });



        initArStuff();
        // initNonArStuff();
        // initDeveloperHelpers();

        renderLoop(renderer.current, scene.current, camera.current);
        console.log(scene.current);
    }, []);

    function initDeveloperHelpers() {
        scene.current.add(new THREE.AxesHelper(3));
        scene.current.add(new THREE.GridHelper(40, 40, 0xeeeeee, 0xeeeeee));

        const textMaterial = new THREE.MeshPhongMaterial({
            color: new THREE.Color(0x999999).convertSRGBToLinear(),
            shininess: 30
        });

        const fontLoader = new THREE.FontLoader();
        fontLoader.load('/helvetiker_regular.typeface.json', (font) => {
            // add light helpers
            scene.current.traverse((child) => {
                if (child.type === "DirectionalLight") {
                    scene.current.add(new THREE.DirectionalLightHelper(child, 0.5, 0x999999));

                    const textGeometry = new THREE.TextGeometry(child.name, {
                        font: font,
                        size: 0.1,
                        height: 0.01,
                        curveSegments: 2,
                        bevelEnabled: false
                    });
                    const textMesh = new THREE.Mesh(textGeometry, textMaterial);
                    textMesh.position.copy(child.position);

                    scene.current.add(textMesh);
                }
            });
        });
    }

    function initNonArStuff() {
        const renderDimensions = getRenderDimensions();

        //renderer.current.setClearColor(new THREE.Color('lightgrey'), 0)
        renderer.current.setSize(renderDimensions.width, renderDimensions.height);

        camera.current = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 0.01, 100);
        // const camera = new PerspectiveCamera();
        camera.current.position.x = -20;
        camera.current.position.y = 4;
        camera.current.position.z = -20;

        orbitControls.current = new THREE.OrbitControls(camera.current, renderer.current.domElement);
        window.addEventListener('resize', function () {
            onResizeNonAr();
        });
    }

    function getRenderDimensions() {
        return {
            width: canvasWrapper.current.offsetWidth,
            height: canvasWrapper.current.offsetHeight
        }
    }

    function onResizeNonAr() {
        const renderDimensions = getRenderDimensions();
        camera.current.aspect = renderDimensions.width / renderDimensions.height;
        camera.current.updateProjectionMatrix();
        renderer.current.setSize(renderDimensions.width, renderDimensions.height);
    }

    function initArStuff() {
        renderer.current.setClearColor(new THREE.Color('lightgrey'), 0)
        renderer.current.setSize(640, 480);

        camera.current = new THREE.Camera();
        scene.current.add(camera.current);


        arToolkitSource.current = new THREEx.ArToolkitSource({
            // to read from the webcam
            sourceType : 'webcam',
            // sourceWidth: window.innerWidth > window.innerHeight ? 640 : 480,
            // sourceHeight: window.innerWidth > window.innerHeight ? 480 : 640,
        });

        arToolkitSource.current.init(function onReady(){
            setTimeout(() => {
                onResize()
            }, 1000);
        })

        // handle resize
        window.addEventListener('resize', function(){
            onResize();
        })

        // create atToolkitContext
        arToolkitContext.current = new THREEx.ArToolkitContext({
            cameraParametersUrl: '/camera_para.dat',
            detectionMode: 'mono',
        });

        // initialize it
        arToolkitContext.current.init(() => {
            camera.current.projectionMatrix.copy(arToolkitContext.current.getProjectionMatrix());
        });

        // update artoolkit on every frame
        onRenderFcts.current.push(() => {
            if (arToolkitSource.current.ready === false) return;
            // camera.updateProjectionMatrix();
            // console.log(arToolkitSource.current.domElement);
            arToolkitContext.current.update(arToolkitSource.current.domElement);
            // update scene.visible if the marker is seen
            scene.current.visible = camera.current.visible;
        });

        // init controls for camera
        arMarkerControls.current = new THREEx.ArMarkerControls(arToolkitContext.current, camera.current, {
            type: 'pattern',
            patternUrl: '/patt.hiro',
            smooth: true,
            // as we controls the camera, set changeMatrixMode: 'cameraTransformMatrix'
            changeMatrixMode: 'cameraTransformMatrix'
        });
    }

    function renderLoop() {
        onRenderFcts.current.forEach((onRenderFct) => {
            onRenderFct();
        });

        if (wallInScene.current !== undefined) {
            wallInScene.current.rotation.z += 0.01;
        }

        renderer.current.render(scene.current, camera.current);

        requestAnimationFrame(() => {
            renderLoop();
        });
    }

    function onResize() {
        arToolkitSource.current.onResizeElement();
        arToolkitSource.current.copyElementSizeTo(renderer.current.domElement)
        if (arToolkitContext.current.arController !== null ){
            arToolkitSource.current.copyElementSizeTo(arToolkitContext.current.arController.canvas);
        }
    }

    return (
        <Box className={classes.rootBox}>
            <div className={classes.canvasWrapper} ref={canvasWrapper}>
                <canvas style={{
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                }}
                        className={classes.canvas}
                        ref={canvas}>

                </canvas>
            </div>
        </Box>
    )
}

export default WallViewerRoute;